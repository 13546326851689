import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/types';
import 'moment/locale/ar';

const SummaryPaymentInfo = (props: Props) => {
  const { cssClass } = props;
  const { t } = useTranslation();

  const subscription = useSelector<RootState, boolean>(
    (state) => state.shoppingCart.subscription
  );
  const price = useSelector<RootState, number>(
    (state) => state.shoppingCart.price
  );
  const free = price === 0;

  const paymentType = useMemo(() => {
    return subscription ? t('monthlySubscription') : t('oneOffPayment');
  }, [subscription, t]);

  return (
    <>
      <p className={cssClass}>{paymentType}</p>
      <p className={cssClass}>
        {t('total')}: £{price.toString()}
      </p>

      {subscription && !free && (
        <p className={cssClass}>
          {t('nextPayment')}: {moment().add(1, 'month').format('MMMM Do YYYY')}
        </p>
      )}
    </>
  );
};

interface Props {
  cssClass: string;
}

export default SummaryPaymentInfo;
