import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import images from '../assets/assets';
import BlackSeparator from '../components/BlackSeparator';
import DiscountCode from '../components/landing/DiscountCode';
import SYDInfo from '../components/Footer';
import PasswordRequirements from '../components/landing/PasswordRequirements';
import useLandingPageStyles from '../styles/landingPageStyles';
import TextInput from '../components/common/TextInput';
import PrimaryButton from '../components/common/PrimaryButton';
import SelectInput from '../components/common/SelectInput';
import Separator from '../components/common/Separator';
import useSignUpValidation from '../validators/signUpValidator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch, RootState } from '../common/types';
import { addDiscountCode, userEmailAvailable } from '../redux/reducers/actions';
import analytics from '../common/analytics';
import useMedia from '../common/media';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../components/common/CountrySelect';
import { environment } from '../common/environments';

const LandingPage = () => {
  const landingClasses = useLandingPageStyles();
  const isDownMD = useMediaQuery('(max-width: 1000px)');
  const { direction, xs, mediaClasses } = useMedia();

  const { controlProps, buttonDisabled, data } = useSignUpValidation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { premium, free, hasLoaded } = useSelector<
    RootState,
    { premium: boolean; free: boolean; hasLoaded: boolean }
  >((state) => ({
    premium: state.shoppingCart.membership === 'premium',
    free: state.shoppingCart.price === 0,
    hasLoaded: state.shoppingCart.hasLoaded,
  }));
  const prevHasLoaded = useRef(hasLoaded);

  const { t } = useTranslation();
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch({ type: 'redux/reset' });
  }, [dispatch]);

  const nextScreen = useMemo(() => {
    if (free) {
      if (premium) {
        return '/delivery';
      } else {
        return '/confirmation';
      }
    }
    return '/card';
  }, [free, premium]);

  useEffect(() => {
    if (!prevHasLoaded.current && hasLoaded) {
      history.push(nextScreen);
    }
    prevHasLoaded.current = hasLoaded;
  }, [hasLoaded, history, nextScreen]);

  const discountCode = useMemo(() => {
    return !data.discountCode
      ? environment.defaultDiscountCode
      : data.discountCode;
  }, [data.discountCode]);

  const onSignUpPressed = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    const continueToNextStep = () => {
      dispatch(addDiscountCode(discountCode, data))
        .then(() => {
          setLoading(false);

          analytics.sendEvent('Website Personal Data Entered');
        })
        .catch(() => {
          setLoading(false);
          alert(t('anErrorOccuredDiscountCode'));
        });
    };

    const emailTakenError = () => {
      setLoading(false);
      alert(t('thisEmailIsAlreadyRegistered'));
    };

    userEmailAvailable(data.email!)
      .then((available) => {
        if (available) {
          continueToNextStep();
        } else {
          emailTakenError();
        }
      })
      .catch(emailTakenError);
  }, [data, discountCode, dispatch, loading, t]);

  const get50OffWithMyGP = useMemo(() => {
    return (
      <Grid item className={landingClasses.topSpace}>
        <div style={{ position: 'relative', height: '148px' }}>
          <img
            src={images.landingPageBanner}
            style={{ height: '148px', maxWidth: '100%' }}
          />

          <Grid
            className={landingClasses.myGPButton}
            container
            alignItems="center"
          >
            <Grid item xs={4}>
              <img src={images.myGPButton} />
            </Grid>

            <Grid
              item
              container
              direction="column"
              justify="center"
              spacing={4}
              xs
              className={landingClasses.myGPRightContainer}
            >
              <Typography variant="h3">Get 50% off with MyGP</Typography>
              <Typography variant="caption" className={landingClasses.caption}>
                (£1 monthly)
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }, [
    landingClasses.caption,
    landingClasses.myGPButton,
    landingClasses.myGPRightContainer,
    landingClasses.topSpace,
  ]);

  const headerLeftPanel = useMemo(() => {
    return (
      <Grid
        item
        container
        xs={12}
        sm={6}
        spacing={0}
        direction="column"
        justify="center"
        className={landingClasses.root}
      >
        <Grid item className={landingClasses.topSpace}>
          <img src={images.logo} />
        </Grid>

        <Grid item className={landingClasses.topSpace}>
          <Typography variant="h1">
            {t('sydYourDailyLifeQualityCompanion')}
          </Typography>
        </Grid>

        <Grid item className={landingClasses.topSpace}>
          <Typography variant="body1">
            {t('supportingYouWithWhatYouNeed')}
          </Typography>
        </Grid>

        {environment.config.showMyGPassets && get50OffWithMyGP}

        <Grid item container className={landingClasses.buttonContainer}>
          <PrimaryButton
            className={landingClasses.button}
            onClick={() => {
              if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            {t('signUpNow')}
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [
    get50OffWithMyGP,
    landingClasses.button,
    landingClasses.buttonContainer,
    landingClasses.root,
    landingClasses.topSpace,
    t,
  ]);

  const headerRightPanel = useMemo(() => {
    return (
      <Grid item container xs={12} sm={6} className={landingClasses.root}>
        <img src={images.lqiImage} className={landingClasses.lqiImage} />
      </Grid>
    );
  }, [landingClasses.lqiImage, landingClasses.root]);

  const form = useMemo(() => {
    return (
      <div className={landingClasses.formContainer} ref={formRef}>
        <Grid
          container
          xs={12}
          direction="column"
          alignItems="center"
          className={mediaClasses.horizontalMargins}
        >
          <Grid item xs>
            <img src={images.logo} />
          </Grid>

          <Grid item xs>
            <Typography variant="h2">{t('joinSydToday')}</Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            direction={direction}
            className={landingClasses.formTopMargin}
          >
            <Grid item container xs={xs} direction="row">
              <TextInput
                label={t('firstName')}
                controlProps={controlProps('firstName')}
              />
            </Grid>
            <Grid item container xs={xs} className={landingClasses.formSpace}>
              <TextInput
                label={t('lastName')}
                controlProps={controlProps('lastName')}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} direction={direction}>
            <Grid item container xs={xs}>
              <SelectInput
                label={t('gender')}
                controlProps={controlProps('gender')}
              />
            </Grid>
            <Grid item container xs={xs} className={landingClasses.formSpace}>
              <TextInput
                label={t('birthDate')}
                date
                controlProps={controlProps('birthDate')}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={landingClasses.formHorizontalMargin}
          >
            <CountrySelect controlProps={controlProps('country')} />
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={landingClasses.formHorizontalMargin}
          >
            <TextInput
              label={t('yourEmail')}
              controlProps={controlProps('email')}
            ></TextInput>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={landingClasses.formHorizontalMargin}
          >
            <TextInput
              label={t('confirmYourEmail')}
              controlProps={controlProps('confirmEmail')}
            ></TextInput>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={landingClasses.formHorizontalMargin}
          >
            <TextInput
              label={t('yourPassword')}
              password
              controlProps={controlProps('password')}
            ></TextInput>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={landingClasses.formHorizontalMargin}
          >
            <TextInput
              label={t('confirmYourPassword')}
              password
              controlProps={controlProps('confirmPassword')}
            ></TextInput>
          </Grid>
        </Grid>

        <PasswordRequirements />

        <Grid item container xs={12} className={mediaClasses.horizontalMargins}>
          <BlackSeparator />
        </Grid>

        <DiscountCode controlProps={controlProps('discountCode')} />

        <Grid
          item
          xs={12}
          className={`${landingClasses.formTopMargin} ${mediaClasses.horizontalMargins}`}
        >
          <PrimaryButton
            disabled={buttonDisabled}
            onClick={onSignUpPressed}
            loading={loading}
          >
            {t('signUp')}
          </PrimaryButton>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${landingClasses.formSmallMargin} ${mediaClasses.horizontalMargins}`}
        >
          <span className={landingClasses.termsAndConditions}>
            {t('byContinuingToUseSYD')}{' '}
            <a
              href="https://syd.iamyiam.com/terms/user_terms.html"
              target="_blank"
            >
              {t('termsAndConditions')}
            </a>
          </span>
        </Grid>

        <SYDInfo />
      </div>
    );
  }, [
    buttonDisabled,
    controlProps,
    direction,
    landingClasses.formContainer,
    landingClasses.formHorizontalMargin,
    landingClasses.formSmallMargin,
    landingClasses.formSpace,
    landingClasses.formTopMargin,
    landingClasses.termsAndConditions,
    loading,
    mediaClasses.horizontalMargins,
    onSignUpPressed,
    t,
    xs,
  ]);

  return (
    <Grid
      container
      spacing={0}
      direction={isDownMD ? 'column' : 'row'}
      alignItems="center"
    >
      {headerLeftPanel}
      {headerRightPanel}

      <Grid item container xs={12} className={mediaClasses.horizontalMargins}>
        <Separator />
      </Grid>
      {form}
    </Grid>
  );
};

export default LandingPage;
