import { Config, myGPConfig, sydConfig } from '../config';

interface Environment {
  apiURL: string;
  amplitudeAPIKey: string;
  customerIOSiteId: string;
  customerIOAPIKey: string;

  autoCompleteFields: boolean;
  language: 'en' | 'ar';
  defaultDiscountCode: string;

  config: Config;
}

const alphaEnvironment: Environment = {
  apiURL: 'https://alpha.app.iamyiam.com/api/',
  amplitudeAPIKey: '2325935557c37c6717c3fee5e9ff479e',
  customerIOSiteId: 'bdd632902a5d4fafee6e',
  customerIOAPIKey: '849815bfc08dae9a9b7a',
  autoCompleteFields: false,
  language: 'en',
  defaultDiscountCode: 'test_website_subscription',
  config: sydConfig,
};

const alphaEnvironmentArabic: Environment = {
  ...alphaEnvironment,
  language: 'ar',
};

const productionEnvironment: Environment = {
  apiURL: 'https://www.iamyiam.com/api',
  amplitudeAPIKey: 'b204f9666a6102ed176606f2260b908c',
  customerIOSiteId: 'ecaab280a79372cdc436',
  customerIOAPIKey: '83ae7d866deb25c979cf',
  autoCompleteFields: false,
  language: 'en',
  defaultDiscountCode: 'website_payment_subscription',
  config: sydConfig,
};

const productionEnvironmentArabic: Environment = {
  ...productionEnvironment,
  language: 'ar',
};

const alphaMyGPEnvironment: Environment = {
  ...alphaEnvironment,
  config: myGPConfig,
};

const productionMyGPEnvironment: Environment = {
  ...productionEnvironment,
  defaultDiscountCode: 'MyGP50',
  config: myGPConfig,
};

const localEnvironment: Environment = {
  ...alphaEnvironment,
  apiURL: 'http://localhost:6001/api/',
};

const devEnvironment: Environment = {
  ...alphaEnvironment,
  autoCompleteFields: true,
};

const devMyGPEnvironment: Environment = {
  ...alphaEnvironment,
  config: sydConfig,
  autoCompleteFields: true,
};

const localDevEnvironment: Environment = {
  ...localEnvironment,
  autoCompleteFields: true,
};

/**
 * !!!!!!!!!
 *
 * DO NOT FORGET TO UPDATE THE SITE ID FOR THE CUSTOMER IO
 * SITE ID IN public/index.html SCRIPT
 */
export const environment = productionEnvironmentArabic;
